import React, { Component } from "react";
import { api } from "../../config/config";
import { Link } from "react-router-dom";

export default class Booksearch extends Component {

    constructor(props) {
        super(props);
        this.state =
        {
           
        };

        // This binding is necessary to make `this` work in the callback    
        // this.VerifyLogin = this.VerifyLogin.bind(this);
        // this.handleUserEmailChange = this.handleUserEmailChange.bind(this);
        // this.handlePasswordChange = this.handlePasswordChange.bind(this);
    }

    render() {
        return (
            <div>
                        {/* <!-- Start: Header Section --> */} 
                        <header id="header" className="navbar-wrapper">
                        <div className="container-fluid">
                            <div className="row">
                                <nav className="navbar navbar-default">
                                            <div className="menu-wrap">
                                                <div className="navbar-header">
                                                    <div className="navbar-brand">
                                                        <h1>
                                                            <Link to={`/`}><img src="images/uvs_e_library_logo.png" alt="UVS e-Library" /></Link>
                                                        </h1>
                                                    </div>
                                                </div>
                                                {/* <!-- Navigation --> */}
                                                <div className="navbar-collapse hidden-sm hidden-xs">
                                                    <ul className="nav navbar-nav">
                                                        <li>
                                                            <Link to={`/`}>Home</Link>
                                                        </li>
                                                        <li className="active">
                                                            <Link to={`/booksearch`}>Books &amp; Media</Link>
                                                        </li>
                                                        <li className="dropdown">
                                                            <a data-toggle="dropdown" className="dropdown-toggle disabled" href="news-events-list-view.html">News &amp; Events</a>
                                                            <ul className="dropdown-menu">
                                                                <li><a href="news-events-list-view.html">News &amp; Events List View</a></li>
                                                                <li><a href="news-events-detail.html">News &amp; Events Detail</a></li>
                                                            </ul>
                                                        </li>
                                                        <li><a href="services.html">Services</a></li>
                                                        <li><a href="contact.html">Contact</a></li>
                                                    </ul>
                                                    {/* <!-- Header Topbar --> */}
                                                    <div className="header-topbar hidden-md">
                                                        <div className="topbar-links">
                                                            <a href="https://uvslibrary.edu.mm/admin"><i className="fa fa-lock"></i>Login</a>
                                                        </div>
                                                    </div>
                                                    {/* <!-- Header Topbar --> */}
                                                </div>
                                                <div className="header-socialbar hidden-sm hidden-xs">
                                                    <ul className="social-links">
                                                        <li><a href="#"><i className="fa fa-facebook"></i></a></li>
                                                        <li><a href="#"><i className="fa fa-twitter"></i></a></li>
                                                        <li><a href="#"><i className="fa fa-google-plus"></i></a></li>
                                                        <li><a href="#"><i className="fa fa-linkedin"></i></a></li>
                                                        <li><a href="#"><i className="fa fa-rss"></i></a></li>
                                                    </ul>
                                                    <ul className="share-links">
                                                        <li className="dropdown">
                                                            <a data-toggle="dropdown" className="dropdown-toggle" href="#">
                                                                <i className="fa fa-share"></i>
                                                            </a>
                                                            <div className="dropdown-menu social-dropdown">
                                                                <ul>
                                                                    <li><a href="#"><i className="fa fa-facebook"></i></a></li>
                                                                    <li><a href="#"><i className="fa fa-twitter"></i></a></li>
                                                                    <li><a href="#"><i className="fa fa-google-plus"></i></a></li>
                                                                    <li><a href="#"><i className="fa fa-linkedin"></i></a></li>
                                                                    <li><a href="#"><i className="fa fa-rss"></i></a></li>
                                                                </ul>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="mobile-menu hidden-md hidden-lg">
                                            <a href="#mobile-menu"><i className="fa fa-navicon"></i></a>
                                                <div id="mobile-menu">
                                                    <ul>
                                                        <li className="mobile-title">
                                                            <h4>Navigation</h4>
                                                            <a href="#" className="close"></a>
                                                        </li>
                                                        <li>
                                                            <a href="index-2.html">Home</a>
                                                        </li>
                                                        <li>
                                                            <a href="books-media-list-view.html">Books &amp; Media</a>
                                                        </li>
                                                        <li>
                                                            <a href="news-events-list-view.html">News &amp; Events</a>
                                                        </li>
                                                        <li><a href="contact.html">Contact</a></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </nav>
                                    </div>
                                </div>
                            </header>
                            {/* <!-- End: Header Section -->

                        {/* <!-- Start: Page Banner --> */}
                            <section className="page-banner services-banner">
                                <div className="container">
                                    <div className="banner-header">
                                        <h2>Books & Media Listing</h2>
                                        <span className="underline center"></span>
                                        <p className="lead">Access any books on your finger tips.</p>
                                    </div>
                                    <div className="breadcrumb">
                                        <ul>
                                            <li><a href="index-2.html">Home</a></li>
                                            <li>Books & Media</li>
                                        </ul>
                                    </div>
                                </div>
                            </section>
                            {/* <!-- End: Page Banner --> */}

                            {/* <!-- Start: Products Section --> */}
                            <div id="content" className="site-content">
                                <div id="primary" className="content-area">
                                    <main id="main" className="site-main">
                                        <div className="books-full-width">
                                            <div className="container">
                                                {/* <!-- Start: Search Section --> */}
                                                <section className="search-filters">
                                                    <div className="filter-box">
                                                        <h3>What are you looking for at the library?</h3>
                                                        <form action="http://libraria.demo.presstigers.com/index.html" method="get">
                                                            <div className="col-md-4 col-sm-6">
                                                                <div className="form-group">
                                                                    <input className="form-control" placeholder="Search by Keyword" id="keywords" name="keywords" type="text" />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3 col-sm-6">
                                                                <div className="form-group">
                                                                    <select name="author" id="author" className="form-control">
                                                                        <option>All Authors</option>
                                                                        <option>Author 01</option>
                                                                        <option>Author 02</option>
                                                                        <option>Author 03</option>
                                                                        <option>Author 04</option>
                                                                        <option>Author 05</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3 col-sm-6">
                                                                <div className="form-group">
                                                                    <select name="category" id="category" className="form-control">
                                                                        <option>All Categories</option>
                                                                        <option>Category 01</option>
                                                                        <option>Category 02</option>
                                                                        <option>Category 03</option>
                                                                        <option>Category 04</option>
                                                                        <option>Category 05</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-2 col-sm-6">
                                                                <div className="form-group">
                                                                    <input className="form-control" type="submit" value="Search" />
                                                                </div>
                                                            </div>
                                                        </form>
                                                    </div>
                                                    <div className="clear"></div>
                                                </section>
                                                {/* <!-- End: Search Section --> */}
                                                
                                                {/* <div className="filter-options margin-list">
                                                    <div className="row">                                            
                                                        <div className="col-md-3 col-sm-3">
                                                            <select name="orderby" className="form-control">
                                                                <option selected="selected">Sort by Title</option>
                                                                <option>Sort by popularity</option>
                                                                <option>Sort by rating</option>
                                                                <option>Sort by newness</option>
                                                                <option>Sort by price</option>
                                                            </select>
                                                        </div>
                                                        <div className="col-md-3 col-sm-3">
                                                            <select name="orderby" className="form-control">
                                                                <option selected="selected">Sort by Author</option>
                                                                <option>Sort by popularity</option>
                                                                <option>Sort by rating</option>
                                                                <option>Sort by newness</option>
                                                                <option>Sort by price</option>
                                                            </select>
                                                        </div>
                                                        <div className="col-md-2 col-sm-3">
                                                            <select name="orderby" className="form-control">
                                                                <option selected="selected">All Languages</option>
                                                                <option>English</option>
                                                                <option>Burmese</option>
                                                            </select>
                                                        </div>
                                                        <div className="col-md-2 col-sm-3">
                                                            <select name="orderby" className="form-control">
                                                                <option selected="selected">All Publishers</option>
                                                                <option>Publisher 1</option>
                                                                <option>Publisher 2</option>
                                                                <option>Publisher 3</option>
                                                                <option>Publisher 4</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div> */}
                                                <div className="booksmedia-fullwidth">
                                                    <ul>
                                                        <li>
                                                            <div className="book-list-icon blue-icon"></div>
                                                            <figure>
                                                                <a href="books-media-detail-v2.html"><img src="images/books-media/layout-3/books-media-layout3-01.jpg" alt="Book" /></a>
                                                                <figcaption>
                                                                    <header>
                                                                        <h4><a href="books-media-detail-v2.html">The Great Gatsby</a></h4>
                                                                        <p><strong>Author:</strong>  F. Scott Fitzgerald</p>
                                                                        <p><strong>ISBN:</strong>  9781581573268</p>
                                                                    </header>
                                                                    <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. Pellentesque dolor turpis, pulvinar varius.</p>
                                                                    <div className="actions">
                                                                        <ul>
                                                                            <li>
                                                                                <a href="#" target="_blank" data-toggle="blog-tags" data-placement="top" title="Add TO CART">
                                                                                    <i className="fa fa-shopping-cart"></i>
                                                                                </a>
                                                                            </li>
                                                                            <li>
                                                                                <a href="#" target="_blank" data-toggle="blog-tags" data-placement="top" title="Like">
                                                                                    <i className="fa fa-heart"></i>
                                                                                </a>
                                                                            </li>
                                                                            <li>
                                                                                <a href="#" target="_blank" data-toggle="blog-tags" data-placement="top" title="Mail">
                                                                                    <i className="fa fa-envelope"></i>
                                                                                </a>
                                                                            </li>
                                                                            <li>
                                                                                <a href="#" target="_blank" data-toggle="blog-tags" data-placement="top" title="Search">
                                                                                    <i className="fa fa-search"></i>
                                                                                </a>
                                                                            </li>
                                                                            <li>
                                                                                <a href="#" target="_blank" data-toggle="blog-tags" data-placement="top" title="Print">
                                                                                    <i className="fa fa-print"></i>
                                                                                </a>
                                                                            </li>
                                                                            <li>
                                                                                <a href="#" target="_blank" data-toggle="blog-tags" data-placement="top" title="Share">
                                                                                    <i className="fa fa-share-alt"></i>
                                                                                </a>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </figcaption>
                                                            </figure>                                                
                                                        </li>
                                                        <li>
                                                            <div className="book-list-icon yellow-icon"></div>
                                                            <figure>
                                                                <a href="books-media-detail-v2.html"><img src="images/books-media/layout-3/books-media-layout3-02.jpg" alt="Book" /></a>
                                                                <figcaption>
                                                                    <header>
                                                                        <h4><a href="books-media-detail-v2.html">The Great Gatsby</a></h4>
                                                                        <p><strong>Author:</strong>  F. Scott Fitzgerald</p>
                                                                        <p><strong>ISBN:</strong>  9781581573268</p>
                                                                    </header>
                                                                    <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. Pellentesque dolor turpis, pulvinar varius.</p>
                                                                    <div className="actions">
                                                                        <ul>
                                                                            <li>
                                                                                <a href="#" target="_blank" data-toggle="blog-tags" data-placement="top" title="Add TO CART">
                                                                                    <i className="fa fa-shopping-cart"></i>
                                                                                </a>
                                                                            </li>
                                                                            <li>
                                                                                <a href="#" target="_blank" data-toggle="blog-tags" data-placement="top" title="Like">
                                                                                    <i className="fa fa-heart"></i>
                                                                                </a>
                                                                            </li>
                                                                            <li>
                                                                                <a href="#" target="_blank" data-toggle="blog-tags" data-placement="top" title="Mail"><i className="fa fa-envelope"></i>
                                                                                </a>
                                                                            </li>
                                                                            <li>
                                                                                <a href="#" target="_blank" data-toggle="blog-tags" data-placement="top" title="Search">
                                                                                    <i className="fa fa-search"></i>
                                                                                </a>
                                                                            </li>
                                                                            <li>
                                                                                <a href="#" target="_blank" data-toggle="blog-tags" data-placement="top" title="Print">
                                                                                    <i className="fa fa-print"></i>
                                                                                </a>
                                                                            </li>
                                                                            <li>
                                                                                <a href="#" target="_blank" data-toggle="blog-tags" data-placement="top" title="Share">
                                                                                    <i className="fa fa-share-alt"></i>
                                                                                </a>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </figcaption>
                                                            </figure>                                                
                                                        </li>
                                                        <li>
                                                            <div className="book-list-icon green-icon"></div>
                                                            <figure>
                                                                <a href="books-media-detail-v2.html"><img src="images/books-media/layout-3/books-media-layout3-03.jpg" alt="Book" /></a>
                                                                <figcaption>
                                                                    <header>
                                                                        <h4><a href="books-media-detail-v2.html">The Great Gatsby</a></h4>
                                                                        <p><strong>Author:</strong>  F. Scott Fitzgerald</p>
                                                                        <p><strong>ISBN:</strong>  9781581573268</p>
                                                                    </header>
                                                                    <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. Pellentesque dolor turpis, pulvinar varius.</p>
                                                                    <div className="actions">
                                                                        <ul>
                                                                            <li>
                                                                                <a href="#" target="_blank" data-toggle="blog-tags" data-placement="top" title="Add TO CART">
                                                                                    <i className="fa fa-shopping-cart"></i>
                                                                                </a>
                                                                            </li>
                                                                            <li>
                                                                                <a href="#" target="_blank" data-toggle="blog-tags" data-placement="top" title="Like">
                                                                                    <i className="fa fa-heart"></i>
                                                                                </a>
                                                                            </li>
                                                                            <li>
                                                                                <a href="#" target="_blank" data-toggle="blog-tags" data-placement="top" title="Mail"><i className="fa fa-envelope"></i>
                                                                                </a>
                                                                            </li>
                                                                            <li>
                                                                                <a href="#" target="_blank" data-toggle="blog-tags" data-placement="top" title="Search">
                                                                                    <i className="fa fa-search"></i>
                                                                                </a>
                                                                            </li>
                                                                            <li>
                                                                                <a href="#" target="_blank" data-toggle="blog-tags" data-placement="top" title="Print">
                                                                                    <i className="fa fa-print"></i>
                                                                                </a>
                                                                            </li>
                                                                            <li>
                                                                                <a href="#" target="_blank" data-toggle="blog-tags" data-placement="top" title="Share">
                                                                                    <i className="fa fa-share-alt"></i>
                                                                                </a>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </figcaption>
                                                            </figure>                                                
                                                        </li>
                                                        <li>
                                                            <div className="book-list-icon yellow-icon"></div>
                                                            <figure>
                                                                <a href="books-media-detail-v2.html"><img src="images/books-media/layout-3/books-media-layout3-04.jpg" alt="Book" /></a>
                                                                <figcaption>
                                                                    <header>
                                                                        <h4><a href="books-media-detail-v2.html">The Great Gatsby</a></h4>
                                                                        <p><strong>Author:</strong>  F. Scott Fitzgerald</p>
                                                                        <p><strong>ISBN:</strong>  9781581573268</p>
                                                                    </header>
                                                                    <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. Pellentesque dolor turpis, pulvinar varius.</p>
                                                                    <div className="actions">
                                                                        <ul>
                                                                            <li>
                                                                                <a href="#" target="_blank" data-toggle="blog-tags" data-placement="top" title="Add TO CART">
                                                                                    <i className="fa fa-shopping-cart"></i>
                                                                                </a>
                                                                            </li>
                                                                            <li>
                                                                                <a href="#" target="_blank" data-toggle="blog-tags" data-placement="top" title="Like">
                                                                                    <i className="fa fa-heart"></i>
                                                                                </a>
                                                                            </li>
                                                                            <li>
                                                                                <a href="#" target="_blank" data-toggle="blog-tags" data-placement="top" title="Mail"><i className="fa fa-envelope"></i>
                                                                                </a>
                                                                            </li>
                                                                            <li>
                                                                                <a href="#" target="_blank" data-toggle="blog-tags" data-placement="top" title="Search">
                                                                                    <i className="fa fa-search"></i>
                                                                                </a>
                                                                            </li>
                                                                            <li>
                                                                                <a href="#" target="_blank" data-toggle="blog-tags" data-placement="top" title="Print">
                                                                                    <i className="fa fa-print"></i>
                                                                                </a>
                                                                            </li>
                                                                            <li>
                                                                                <a href="#" target="_blank" data-toggle="blog-tags" data-placement="top" title="Share">
                                                                                    <i className="fa fa-share-alt"></i>
                                                                                </a>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </figcaption>
                                                            </figure>                                                
                                                        </li>
                                                        <li>
                                                            <div className="book-list-icon blue-icon"></div>
                                                            <figure>
                                                                <a href="books-media-detail-v2.html"><img src="images/books-media/layout-3/books-media-layout3-05.jpg" alt="Book" /></a>
                                                                <figcaption>
                                                                    <header>
                                                                        <h4><a href="books-media-detail-v2.html">The Great Gatsby</a></h4>
                                                                        <p><strong>Author:</strong>  F. Scott Fitzgerald</p>
                                                                        <p><strong>ISBN:</strong>  9781581573268</p>
                                                                    </header>
                                                                    <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. Pellentesque dolor turpis, pulvinar varius.</p>
                                                                    <div className="actions">
                                                                        <ul>
                                                                            <li>
                                                                                <a href="#" target="_blank" data-toggle="blog-tags" data-placement="top" title="Add TO CART">
                                                                                    <i className="fa fa-shopping-cart"></i>
                                                                                </a>
                                                                            </li>
                                                                            <li>
                                                                                <a href="#" target="_blank" data-toggle="blog-tags" data-placement="top" title="Like">
                                                                                    <i className="fa fa-heart"></i>
                                                                                </a>
                                                                            </li>
                                                                            <li>
                                                                                <a href="#" target="_blank" data-toggle="blog-tags" data-placement="top" title="Mail"><i className="fa fa-envelope"></i>
                                                                                </a>
                                                                            </li>
                                                                            <li>
                                                                                <a href="#" target="_blank" data-toggle="blog-tags" data-placement="top" title="Search">
                                                                                    <i className="fa fa-search"></i>
                                                                                </a>
                                                                            </li>
                                                                            <li>
                                                                                <a href="#" target="_blank" data-toggle="blog-tags" data-placement="top" title="Print">
                                                                                    <i className="fa fa-print"></i>
                                                                                </a>
                                                                            </li>
                                                                            <li>
                                                                                <a href="#" target="_blank" data-toggle="blog-tags" data-placement="top" title="Share">
                                                                                    <i className="fa fa-share-alt"></i>
                                                                                </a>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </figcaption>
                                                            </figure>                                                
                                                        </li>
                                                        <li>
                                                            <div className="book-list-icon red-icon"></div>
                                                            <figure>
                                                                <a href="books-media-detail-v2.html"><img src="images/books-media/layout-3/books-media-layout3-06.jpg" alt="Book" /></a>
                                                                <figcaption>
                                                                    <header>
                                                                        <h4><a href="books-media-detail-v2.html">The Great Gatsby</a></h4>
                                                                        <p><strong>Author:</strong>  F. Scott Fitzgerald</p>
                                                                        <p><strong>ISBN:</strong>  9781581573268</p>
                                                                    </header>
                                                                    <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. Pellentesque dolor turpis, pulvinar varius.</p>
                                                                    <div className="actions">
                                                                        <ul>
                                                                            <li>
                                                                                <a href="#" target="_blank" data-toggle="blog-tags" data-placement="top" title="Add TO CART">
                                                                                    <i className="fa fa-shopping-cart"></i>
                                                                                </a>
                                                                            </li>
                                                                            <li>
                                                                                <a href="#" target="_blank" data-toggle="blog-tags" data-placement="top" title="Like">
                                                                                    <i className="fa fa-heart"></i>
                                                                                </a>
                                                                            </li>
                                                                            <li>
                                                                                <a href="#" target="_blank" data-toggle="blog-tags" data-placement="top" title="Mail"><i className="fa fa-envelope"></i>
                                                                                </a>
                                                                            </li>
                                                                            <li>
                                                                                <a href="#" target="_blank" data-toggle="blog-tags" data-placement="top" title="Search">
                                                                                    <i className="fa fa-search"></i>
                                                                                </a>
                                                                            </li>
                                                                            <li>
                                                                                <a href="#" target="_blank" data-toggle="blog-tags" data-placement="top" title="Print">
                                                                                    <i className="fa fa-print"></i>
                                                                                </a>
                                                                            </li>
                                                                            <li>
                                                                                <a href="#" target="_blank" data-toggle="blog-tags" data-placement="top" title="Share">
                                                                                    <i className="fa fa-share-alt"></i>
                                                                                </a>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </figcaption>
                                                            </figure>                                                
                                                        </li>
                                                        <li>
                                                            <div className="book-list-icon green-icon"></div>
                                                            <figure>
                                                                <a href="books-media-detail-v2.html"><img src="images/books-media/layout-3/books-media-layout3-07.jpg" alt="Book" /></a>
                                                                <figcaption>
                                                                    <header>
                                                                        <h4><a href="books-media-detail-v2.html">The Great Gatsby</a></h4>
                                                                        <p><strong>Author:</strong>  F. Scott Fitzgerald</p>
                                                                        <p><strong>ISBN:</strong>  9781581573268</p>
                                                                    </header>
                                                                    <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. Pellentesque dolor turpis, pulvinar varius.</p>
                                                                    <div className="actions">
                                                                        <ul>
                                                                            <li>
                                                                                <a href="#" target="_blank" data-toggle="blog-tags" data-placement="top" title="Add TO CART">
                                                                                    <i className="fa fa-shopping-cart"></i>
                                                                                </a>
                                                                            </li>
                                                                            <li>
                                                                                <a href="#" target="_blank" data-toggle="blog-tags" data-placement="top" title="Like">
                                                                                    <i className="fa fa-heart"></i>
                                                                                </a>
                                                                            </li>
                                                                            <li>
                                                                                <a href="#" target="_blank" data-toggle="blog-tags" data-placement="top" title="Mail"><i className="fa fa-envelope"></i>
                                                                                </a>
                                                                            </li>
                                                                            <li>
                                                                                <a href="#" target="_blank" data-toggle="blog-tags" data-placement="top" title="Search">
                                                                                    <i className="fa fa-search"></i>
                                                                                </a>
                                                                            </li>
                                                                            <li>
                                                                                <a href="#" target="_blank" data-toggle="blog-tags" data-placement="top" title="Print">
                                                                                    <i className="fa fa-print"></i>
                                                                                </a>
                                                                            </li>
                                                                            <li>
                                                                                <a href="#" target="_blank" data-toggle="blog-tags" data-placement="top" title="Share">
                                                                                    <i className="fa fa-share-alt"></i>
                                                                                </a>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </figcaption>
                                                            </figure>                                                
                                                        </li>
                                                        <li>
                                                            <div className="book-list-icon light-green-icon"></div>
                                                            <figure>
                                                                <a href="books-media-detail-v2.html"><img src="images/books-media/layout-3/books-media-layout3-07.jpg" alt="Book" /></a>
                                                                <figcaption>
                                                                    <header>
                                                                        <h4><a href="books-media-detail-v2.html">The Great Gatsby</a></h4>
                                                                        <p><strong>Author:</strong>  F. Scott Fitzgerald</p>
                                                                        <p><strong>ISBN:</strong>  9781581573268</p>
                                                                    </header>
                                                                    <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. Pellentesque dolor turpis, pulvinar varius.</p>
                                                                    <div className="actions">
                                                                        <ul>
                                                                            <li>
                                                                                <a href="#" target="_blank" data-toggle="blog-tags" data-placement="top" title="Add TO CART">
                                                                                    <i className="fa fa-shopping-cart"></i>
                                                                                </a>
                                                                            </li>
                                                                            <li>
                                                                                <a href="#" target="_blank" data-toggle="blog-tags" data-placement="top" title="Like">
                                                                                    <i className="fa fa-heart"></i>
                                                                                </a>
                                                                            </li>
                                                                            <li>
                                                                                <a href="#" target="_blank" data-toggle="blog-tags" data-placement="top" title="Mail"><i className="fa fa-envelope"></i>
                                                                                </a>
                                                                            </li>
                                                                            <li>
                                                                                <a href="#" target="_blank" data-toggle="blog-tags" data-placement="top" title="Search">
                                                                                    <i className="fa fa-search"></i>
                                                                                </a>
                                                                            </li>
                                                                            <li>
                                                                                <a href="#" target="_blank" data-toggle="blog-tags" data-placement="top" title="Print">
                                                                                    <i className="fa fa-print"></i>
                                                                                </a>
                                                                            </li>
                                                                            <li>
                                                                                <a href="#" target="_blank" data-toggle="blog-tags" data-placement="top" title="Share">
                                                                                    <i className="fa fa-share-alt"></i>
                                                                                </a>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </figcaption>
                                                            </figure>                                                
                                                        </li>
                                                        <li>
                                                            <div className="book-list-icon red-icon"></div>
                                                            <figure>
                                                                <a href="books-media-detail-v2.html"><img src="images/books-media/layout-3/books-media-layout3-09.jpg" alt="Book" /></a>
                                                                <figcaption>
                                                                    <header>
                                                                        <h4><a href="books-media-detail-v2.html">The Great Gatsby</a></h4>
                                                                        <p><strong>Author:</strong>  F. Scott Fitzgerald</p>
                                                                        <p><strong>ISBN:</strong>  9781581573268</p>
                                                                    </header>
                                                                    <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. Pellentesque dolor turpis, pulvinar varius.</p>
                                                                    <div className="actions">
                                                                        <ul>
                                                                            <li>
                                                                                <a href="#" target="_blank" data-toggle="blog-tags" data-placement="top" title="Add TO CART">
                                                                                    <i className="fa fa-shopping-cart"></i>
                                                                                </a>
                                                                            </li>
                                                                            <li>
                                                                                <a href="#" target="_blank" data-toggle="blog-tags" data-placement="top" title="Like">
                                                                                    <i className="fa fa-heart"></i>
                                                                                </a>
                                                                            </li>
                                                                            <li>
                                                                                <a href="#" target="_blank" data-toggle="blog-tags" data-placement="top" title="Mail"><i className="fa fa-envelope"></i>
                                                                                </a>
                                                                            </li>
                                                                            <li>
                                                                                <a href="#" target="_blank" data-toggle="blog-tags" data-placement="top" title="Search">
                                                                                    <i className="fa fa-search"></i>
                                                                                </a>
                                                                            </li>
                                                                            <li>
                                                                                <a href="#" target="_blank" data-toggle="blog-tags" data-placement="top" title="Print">
                                                                                    <i className="fa fa-print"></i>
                                                                                </a>
                                                                            </li>
                                                                            <li>
                                                                                <a href="#" target="_blank" data-toggle="blog-tags" data-placement="top" title="Share">
                                                                                    <i className="fa fa-share-alt"></i>
                                                                                </a>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </figcaption>
                                                            </figure>                                                
                                                        </li>
                                                    </ul>
                                                </div>
                                                <nav className="navigation pagination text-center">
                                                    <h2 className="screen-reader-text">Posts navigation</h2>
                                                    <div className="nav-links">
                                                        <a className="prev page-numbers" href="#."><i className="fa fa-long-arrow-left"></i> Previous</a>
                                                        <a className="page-numbers" href="#.">1</a>
                                                        <span className="page-numbers current">2</span>
                                                        <a className="page-numbers" href="#.">3</a>
                                                        <a className="page-numbers" href="#.">4</a>
                                                        <a className="next page-numbers" href="#.">Next <i className="fa fa-long-arrow-right"></i></a>
                                                    </div>
                                                </nav>
                                            </div>
                                            
                                        </div>
                                    </main>
                                </div>
                            </div>
                            {/* <!-- End: Products Section --> */}
                                                <div>&nbsp;</div>
                    {/* <!-- Start: Footer --> */}
                    <footer className="site-footer">
                        <div className="container">
                            <div id="footer-widgets">
                                <div className="row">
                                    <div className="col-sm widget-container text-center">
                                        <div id="text-2" className="widget widget_text">
                                            <h3 className="footer-widget-title">About UVS e-Library</h3>
                                            <span className="underline left"></span>
                                            <div className="textwidget">
                                                It is a long established fact that a reader will be distracted by the readable content of a page when looking.
                                            </div>
                                            <address>
                                                <div className="info">
                                                    <i className="fa fa-location-arrow"></i>
                                                    <span>University of Veterinary Science, Yezin, Zayar Thiri Township, Naypyidaw, Myanmar.</span>
                                                </div>
                                                <div className="info">
                                                    <i className="fa fa-envelope"></i>
                                                    <span><a href="mailto:contact@uvsyezin.edu.mm">contact@uvsyezin.edu.mm</a></span>
                                                </div>
                                            </address>
                                        </div>
                                    </div>
                                  
                                </div>
                            </div>                
                        </div>
                    </footer>
                    {/* <!-- End: Footer --> */}
            </div>
        );
    }
}