import React from 'react';
import './App.css';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { useHistory } from "react-router-dom";
import Home from "./components/Home/home";
import Booksearch from "./components/BookSearch/booksearch";

function App() {
  let history = useHistory();
  // if(!localStorage.getItem('user_info')) {
  //   history.push("/login");
  // }

  return (
    <Router>
      <div>
        <Switch>
          <Route path="/booksearch/" component={Booksearch} />
          <Route path="/" component={Home} />
        </Switch>
      </div>
    </Router>
  );
}

export default App;
